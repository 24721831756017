import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import './data.css';
import { Tab, Tabs } from '@mui/material';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Container, Table, TableBody, FormControl, InputLabel, Select, MenuItem, TableCell, TableContainer, TableHead, IconButton, List, ListItem, ListItemText, TableRow, Paper, Button, Modal, Box, Typography, RadioGroup, Radio, FormControlLabel, TextField, CircularProgress, Checkbox, Grid } from "@mui/material";
import { format } from 'date-fns';
import Chip from '@mui/material/Chip';

const FormData = () => {
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState({
        form_id: '',
        title: '',
        description: '',
        questions: [], // Ensure questions is initialized as an empty array
    });

    // const [selectedForm, setSelectedForm] = useState(null);
    const [formDetails, setFormDetails] = useState({ title: "", description: "" });
    const [responses, setResponses] = useState({});
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openSendModal, setOpenSendModal] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [employeeResponses, setEmployeeResponses] = useState([]);
    const [openResponseModal, setOpenResponseModal] = useState(false);
    const [formResponses, setFormResponses] = useState([]); // Initialize as an empty array
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());
    const [totalCount, setTotalCount] = useState(0);
    const [newQuestionText, setNewQuestionText] = useState('');
    const [newQuestionType, setNewQuestionType] = useState('');
    const [showNewQuestionFields, setShowNewQuestionFields] = useState(false);
    const [newOptions, setNewOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const filteredEmployees = employees.filter((employee) => employee.name.toLowerCase().includes(searchQuery.toLowerCase()));

    const handleAddNewOption = () => {
        setNewOptions((prevOptions) => [...prevOptions, ""]);
    };


    const handleNewOptionChange = (index, event) => {
        const updatedOptions = [...newOptions];
        updatedOptions[index] = event.target.value;
        setNewOptions(updatedOptions);
    };

    const handleRemoveNewOption = (index) => {
        const updatedOptions = newOptions.filter((_, i) => i !== index);
        setNewOptions(updatedOptions);
    };

    const handleCloseDetailModal = () => setOpenDetailModal(false);

    useEffect(() => {
        const fetchForms = async () => {
            try {
                const response = await fetch("https://feedbacksvc.varnetsolutions.in/show_all_form");
                const data = await response.json();
                setForms(data.forms);
                setTotalCount(data.total_count);
            } catch (error) {
                console.error("Error fetching forms:", error);
            }
        };

        fetchForms();
    }, []);

    const handleOpenSendModal = async (formId) => {
        setLoading(true);
        try {
            const response = await fetch(`https://feedbacksvc.varnetsolutions.in/get_form/${formId}`);
            const data = await response.json();
            setSelectedForm(data); // Set the selected form based on the form ID
            const employeeResponse = await fetch("https://feedbacksvc.varnetsolutions.in/get_all_employees");
            const employeeData = await employeeResponse.json();
            setEmployees(employeeData.employees || []);
            setOpenSendModal(true);
        } catch (error) {
            console.error("Error fetching form or employees:", error);
            setEmployees([]);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenResponseModal = async (formId) => {
        setLoading(true);
        try {
            const response = await fetch(`https://feedbacksvc.varnetsolutions.in/get_form/${formId}`);
            const data = await response.json();
            setSelectedForm(data); // Set the selected form based on the form ID

            const responsesResponse = await fetch(`https://feedbacksvc.varnetsolutions.in/get_form_responses/${formId}`);
            const responsesData = await responsesResponse.json();
            console.log("responce_LLL", responsesData)

            // Ensure responsesData.responses is an array
            if (Array.isArray(responsesData.responses)) {
                setEmployeeResponses(responsesData.responses);
            } else {
                console.warn("Unexpected structure for responses:", responsesData.responses);
                setEmployeeResponses([]);
            }
        } catch (error) {
            console.error("Error fetching form or responses:", error);
            setEmployeeResponses([]);
        } finally {
            setLoading(false);
            setOpenResponseModal(true);
        }
    };

    // const handleOpenResponseModal = async (formId) => {
    //     setLoading(true);
    //     try {
    //         const response = await fetch(`https://feedbacksvc.varnetsolutions.in/get_form/${formId}`);
    //         const data = await response.json();
    //         setSelectedForm(data); // Set the selected form based on the form ID

    //         const responsesResponse = await fetch(`https://feedbacksvc.varnetsolutions.in/get_form_responses/${formId}`);
    //         const responsesData = await responsesResponse.json();
    //         setEmployeeResponses(responsesData.responses || []);
    //         console.log("sdbnbknbkbn https://feedbacksvc.varnetsolutions.in/get_form/${formId}")
    //     } catch (error) {
    //         console.error("Error fetching form or responses:", error);
    //         setEmployeeResponses([]);
    //     } finally {
    //         setLoading(false);
    //         setOpenResponseModal(true);
    //     }
    // };

    const handleOpenModal = async (formId) => {
        setLoading(true);
        try {
            const response = await fetch(`https://feedbacksvc.varnetsolutions.in/get_form/${formId}`);
            const data = await response.json();
            setSelectedForm(data);
            setFormDetails({ title: data.title, description: data.description });
            const initialResponses = data.questions.reduce((acc, question) => {
                acc[question.id] = "";
                return acc;
            }, {});
            setResponses(initialResponses);
        } catch (error) {
            console.error("Error fetching form:", error);
        } finally {
            setLoading(false);
            setOpenModal(true);

        }
    };

    const handleDownload = async (formId) => {
        try {
            const response = await axios.get(`https://feedbacksvc.varnetsolutions.in/get_form_responses_download/${formId}`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `form_responses_${formId}.csv`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };

    const handleOpenDeleteModal = async (formId) => {
        try {
            const response = await axios.delete(`https://feedbacksvc.varnetsolutions.in/delete_form/${formId}`);
            if (response.status === 200) {
                alert("Form deleted successfully!");
                const updatedForms = forms.filter(form => form.form_id !== formId);
                setForms(updatedForms);
            }
            else {
                alert("Failed to delete form!");
            }
        } catch (err) {
            console.error("Error deleting form", err);
        };

    };

    const handleViewResponse = async (response) => {
        setLoading(true);
        setOpenDetailModal(true);
        try {
            const res = await axios.get(`https://feedbacksvc.varnetsolutions.in/get_form_responses_by_emp_id/${selectedForm.form_id}/${response.emp_id}`);
            console.log("API Response Data:", res.data);

            // Ensure the response data is structured correctly
            if (res.data && Array.isArray(res.data.questions_and_answers)) {
                setFormResponses(res.data.questions_and_answers); // Set formResponses with the questions_and_answers array
            } else {
                console.warn("Unexpected response structure:", res.data);
                setFormResponses([]); // Default to an empty array if data is not as expected
            }
        } catch (error) {
            console.error("Error fetching form responses", error);
        } finally {
            setLoading(false);
        }
    };



    // const handleViewResponse = async (response) => {
    //     setLoading(true);
    //     setOpenDetailModal(true);
    //     try {
    //         const res = await axios.get(`https://feedbacksvc.varnetsolutions.in/get_form_responses_by_emp_id/${selectedForm.form_id}/${response.emp_id}`);

    //         console.log("API Response Data:", res.data);  // Log the entire response to check its structure

    //         // Ensure the response data is structured correctly
    //         if (res.data && Array.isArray(res.data.questions_and_answers)) {
    //             setFormResponses(res.data.questions_and_answers); // Set formResponses with the questions_and_answers array
    //         } else {
    //             console.warn("Unexpected response structure:", res.data);
    //             setFormResponses([]); // Default to an empty array if data is not as expected
    //         }
    //     } catch (error) {
    //         console.error("Error fetching form responses", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleSend = async () => {
        if (!selectedForm) return;
        // Inside your function
        const formattedDate = format(selectedDateTime, 'yyyy-MM-dd HH:mm:ss');

        try {
            await fetch("https://feedbacksvc.varnetsolutions.in/send_form_link", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    form_id: selectedForm.form_id,
                    emails: selectedEmployees.map(empId => {
                        const employee = employees.find(emp => emp.emp_id === empId);
                        return employee ? employee.email : null;
                    }).filter(email => email !== null),
                    submit_expiry: formattedDate,
                }),
            });
            alert("Form link sent successfully!");
            setOpenSendModal(false);
        } catch (error) {
            console.error("Error sending form link:", error);
        }
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedForm(null);
        setResponses({});
        setFormDetails({});

    };

    const handleCloseSendModal = () => {
        setOpenSendModal(false);
        setSelectedEmployees([]);
        setSearchQuery('');
    };

    const handleCloseResponseModal = () => {
        setOpenResponseModal(false);
        setEmployeeResponses([]);
    };

    const handleResponseChange = (questionId, value) => {
        setResponses((prevResponses) => ({
            ...prevResponses,
            [questionId]: value,
        }));
    };

    const handleFormDetailsChange = (e) => {
        const { name, value } = e.target;
        setFormDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleQuestionChange = (index, e) => {
        const { name, value } = e.target;
        const updatedQuestions = [...selectedForm.questions];
        updatedQuestions[index] = {
            ...updatedQuestions[index],
            [name]: value,
        };
        setSelectedForm((prevForm) => ({
            ...prevForm,
            questions: updatedQuestions,
        }));
    };



    const handleOptionsChange = (questionIndex, optionIndex, e) => {
        const { value } = e.target;
        const updatedQuestions = [...selectedForm.questions];
        updatedQuestions[questionIndex].options[optionIndex] = value;
        setSelectedForm((prevForm) => ({
            ...prevForm,
            questions: updatedQuestions,
        }));
    };

    const handleAddOption = (questionIndex) => {
        const updatedQuestions = [...selectedForm.questions];
        updatedQuestions[questionIndex].options.push("");
        setSelectedForm((prevForm) => ({
            ...prevForm,
            questions: updatedQuestions,
        }));
    };

    const handleSelectEmployee = (empId, checked) => {
        setSelectedEmployees((prevSelected) =>
            checked
                ? [...prevSelected, empId]
                : prevSelected.filter((id) => id !== empId)
        );
    };

    const handleRemoveOption = (questionIndex, optionIndex) => {
        const updatedQuestions = [...selectedForm.questions];
        updatedQuestions[questionIndex].options.splice(optionIndex, 1);
        setSelectedForm((prevForm) => ({
            ...prevForm,
            questions: updatedQuestions,
        }));
    };

    // const handleQuestionChange = (index, e) => {
    //     const { name, value } = e.target;
    //     const updatedQuestions = [...selectedForm.questions];
    //     updatedQuestions[index] = {
    //         ...updatedQuestions[index],
    //         [name]: value,
    //     };

    //     console.log("Updated Questions:", updatedQuestions); // Debugging: Check the updated questions array

    //     setSelectedForm((prevForm) => ({
    //         ...prevForm,
    //         questions: updatedQuestions,
    //     }));
    //     console.log("selected :", selectedForm)
    // };

    // const handleUpdateForm = async () => {
    //     try {
    //         // Log for debugging
    //         console.log("newQuestionText", newQuestionText);
    //         console.log("newQuestionType", newQuestionType);

    //         // Check if we are adding a new question
    //         if (newQuestionText && newQuestionType) {
    //             // Create a new question object with the required fields
    //             const newQuestion = {
    //                 question_id: new Date().getTime(), // Generate a unique ID for the new question
    //                 question: newQuestionText,
    //                 type: newQuestionType,
    //                 options: newQuestionType === "MCQ" ? newOptions : [], // Ensure options is an array
    //             };

    //             // Add the new question to the list of questions
    //             const updatedQuestions = [...selectedForm.questions, newQuestion];

    //             // Update form data
    //             const updatedForm = {
    //                 payload: {
    //                     title: formDetails.title,
    //                     description: formDetails.description,
    //                     questions: updatedQuestions,
    //                 },
    //             };

    //             console.log("Updated form payload with new question:", updatedForm);

    //             // Send PUT request to update the form
    //             await sendUpdateRequest(updatedForm);
    //         } else {
    //             // If not adding a new question, just update the form with modified questions
    //             const updatedForm = {
    //                 payload: {
    //                     title: formDetails.title,
    //                     description: formDetails.description,
    //                     questions: selectedForm.questions, // Use the modified questions from state
    //                 },
    //             };

    //             console.log("Updated form payload with existing questions:", updatedForm);

    //             // Send PUT request to update the form
    //             await sendUpdateRequest(updatedForm);
    //         }
    //     } catch (error) {
    //         console.error("Error updating form:", error);
    //     } finally {
    //         // Reset state and close modal
    //         setShowNewQuestionFields(false);
    //         setNewQuestionText('');
    //         setNewQuestionType('');
    //         setNewOptions([]);
    //         handleCloseModal();
    //     }
    // };

    const handleUpdateForm = async () => {
        try {
            if (newQuestionText && newQuestionType) {
                // Create a new question object with the required fields
                const newQuestion = {
                    question_id: new Date().getTime(), // Generate a unique ID for the new question
                    question: newQuestionText,
                    type: newQuestionType,
                    options: newQuestionType === "MCQ" ? newOptions : [], // Ensure options is an array
                };

                // Ensure questions is an array and add the new question
                const updatedQuestions = Array.isArray(selectedForm.questions) ? [...selectedForm.questions] : [];
                updatedQuestions.push(newQuestion);

                // Update form data, wrapping it in a payload object
                const updatedForm = {
                    payload: {
                        title: formDetails.title,
                        description: formDetails.description,
                        questions: updatedQuestions,
                    },
                };

                // Debugging: Log the updated form data
                console.log("Updated form payload :", updatedForm);

                // Send PUT request to update the form
                const response = await fetch(`https://feedbacksvc.varnetsolutions.in/update_form/${selectedForm.form_id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updatedForm),
                });

                if (response.ok) {
                    console.log("Form updated successfully");

                    // Update state with the new form data to reflect the changes immediately
                    setSelectedForm(updatedForm.payload);
                } else {
                    console.warn("Failed to update form", await response.text());
                }
            } else {
                console.warn("Question text or type is missing.");
            }
        } catch (error) {
            console.error("Error updating form:", error);
        } finally {
            // Reset state and close modal
            setShowNewQuestionFields(false);
            setNewQuestionText('');
            setNewQuestionType('');
            setNewOptions([]);
            handleCloseModal();
        }
    };

    // Helper function to send the PUT request
    // const sendUpdateRequest = async (updatedForm) => {
    //     try {
    //         const response = await fetch(`https://feedbacksvc.varnetsolutions.in/update_form/${selectedForm.form_id}`, {
    //             method: "PUT",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(updatedForm),
    //         });

    //         if (response.ok) {
    //             console.log("Form updated successfully");

    //             // Update state with the new form data to reflect the changes immediately
    //             setSelectedForm(updatedForm.payload);
    //         } else {
    //             console.warn("Failed to update form", await response.text());
    //         }
    //     } catch (error) {
    //         console.error("Error during form update request:", error);
    //     }
    // };


    // const handleUpdateForm = async () => {
    //     try {
    //         if (newQuestionText && newQuestionType) {
    //             // Create a new question object with the required fields
    //             const newQuestion = {
    //                 question_id: new Date().getTime(), // Generate a unique ID for the new question
    //                 question: newQuestionText,
    //                 type: newQuestionType,
    //                 options: newQuestionType === "MCQ" ? newOptions : [], // Ensure options is an array
    //             };

    //             // Ensure questions is an array and add the new question
    //             const updatedQuestions = Array.isArray(selectedForm.questions) ? [...selectedForm.questions] : [];
    //             updatedQuestions.push(newQuestion);

    //             // Update form data, wrapping it in a payload object
    //             const updatedForm = {
    //                 payload: {
    //                     title: formDetails.title,
    //                     description: formDetails.description,
    //                     questions: updatedQuestions,
    //                 },
    //             };

    //             // Debugging: Log the updated form data
    //             console.log("Updated form payload :", updatedForm);

    //             // Send PUT request to update the form
    //             const response = await fetch(`https://feedbacksvc.varnetsolutions.in/update_form/${selectedForm.form_id}`, {
    //                 method: "PUT",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify(updatedForm),
    //             });

    //             if (response.ok) {
    //                 console.log("Form updated successfully");

    //                 // Update state with the new form data to reflect the changes immediately
    //                 setSelectedForm(updatedForm.payload);
    //             } else {
    //                 console.warn("Failed to update form", await response.text());
    //             }
    //         } else {
    //             console.warn("Question text or type is missing.");
    //         }
    //     } catch (error) {
    //         console.error("Error updating form:", error);
    //     } finally {
    //         // Reset state and close modal
    //         setShowNewQuestionFields(false);
    //         setNewQuestionText('');
    //         setNewQuestionType('');
    //         setNewOptions([]);
    //         handleCloseModal();
    //     }
    // };

    return (
        <div className="specific">
            <div style={{ ...pillStyle, display: 'inline-flex', alignItems: 'center' }}>
                Total Forms: <span style={pillCountStyle}>{totalCount}</span>
            </div>

            <Container>
                <TableContainer component={Paper} >
                    <Table style={{ border: '1px solid #333', borderColor: '#333' }}>
                        <TableHead>
                            <TableRow style={{ border: '1px solid #333', borderColor: '#333', backgroundColor: '#333' }}>
                                <TableCell>
                                    <Typography fontWeight="bold" color="white">📄 FORM ID</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight="bold" color="white">📋 TITLE</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight="bold" textAlign={"center"} color="white">😎 ACTION</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {forms.map((form, index) => (
                                <TableRow
                                    key={form.form_id}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9', // Alternates between white and grey
                                        transition: 'background-color 0.3s',
                                        '&:hover': {
                                            backgroundColor: '#e0e0e0', // Highlight color on hover
                                        },
                                    }}
                                >
                                    <TableCell><Typography fontWeight="bold">{form.form_id}</Typography></TableCell>
                                    <TableCell><Typography fontWeight="bold">{form.title}</Typography></TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleOpenModal(form.form_id)}
                                        >
                                            View Form
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleOpenSendModal(form.form_id)}
                                            sx={{ ml: 1 }}
                                        >
                                            Send
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleOpenResponseModal(form.form_id)}
                                            sx={{ ml: 1 }}
                                        >
                                            View Responses
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleDownload(form.form_id)}
                                            sx={{ ml: 1 }}
                                        >
                                            Download Form
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleOpenDeleteModal(form.form_id)}
                                            sx={{ ml: 1 }}
                                        >
                                            Delete Form
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Modal open={openModal} onClose={handleCloseModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 500,
                            maxHeight: '90vh',
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : selectedForm ? (
                            <>
                                <TextField
                                    name="title"
                                    label="Title"
                                    variant="outlined"
                                    fullWidth
                                    value={formDetails.title || ""}
                                    onChange={handleFormDetailsChange}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={formDetails.description || ""}
                                    onChange={handleFormDetailsChange}
                                    sx={{ mb: 2 }}
                                />
                                {selectedForm.questions.map((question, index) => (
                                    <Box key={question.id} sx={{ marginBottom: 2 }}>
                                        {/* <TextField
                                            name="question"
                                            label={`Question ${index + 1}`}
                                            variant="outlined"
                                            fullWidth
                                            value={question.question || ''} // Ensure there is a fallback value to prevent uncontrolled component warning
                                            onChange={(e) => handleQuestionChange(index, e)}
                                            sx={{ mb: 2 }}
                                        /> */}
                                        <TextField
                                            name="question"
                                            label={`Question ${index + 1}`}
                                            variant="outlined"
                                            fullWidth
                                            value={question.question}
                                            onChange={(e) => handleQuestionChange(index, e)}
                                            sx={{ mb: 2 }}
                                        />
                                        {question.type === "MCQ" && (
                                            <Box>
                                                {question.options.map((option, i) => (
                                                    <Box key={`option-${question.id}-${i}`} sx={{ mb: 1 }}>
                                                        <TextField
                                                            label={`Option ${i + 1}`}
                                                            variant="outlined"
                                                            fullWidth
                                                            value={option}
                                                            onChange={(e) => handleOptionsChange(index, i, e)}
                                                            sx={{ mb: 1 }}
                                                        />
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => handleRemoveOption(index, i)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Remove Option
                                                        </Button>
                                                    </Box>
                                                ))}
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => handleAddOption(index)}
                                                >
                                                    Add Option
                                                </Button>
                                            </Box>
                                        )}
                                        {question.type === "Boolean" && (
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={responses[question.id] === "Yes"}
                                                            onChange={() => handleResponseChange(question.id, "Yes")}
                                                        />
                                                    }
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={responses[question.id] === "No"}
                                                            onChange={() => handleResponseChange(question.id, "No")}
                                                        />
                                                    }
                                                    label="No"
                                                />
                                            </Box>
                                        )}
                                        {question.type === "ShortAnswer" && (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={responses[question.id] || ""}
                                                onChange={(e) => handleResponseChange(question.id, e.target.value)}
                                            />
                                        )}
                                        {question.type === "Paragraph" && (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                value={responses[question.id] || ""}
                                                onChange={(e) => handleResponseChange(question.id, e.target.value)}
                                            />
                                        )}
                                    </Box>
                                ))}

                                {showNewQuestionFields && (
                                    <>
                                        <TextField
                                            label="New Question"
                                            variant="outlined"
                                            fullWidth
                                            value={newQuestionText}
                                            onChange={(e) => setNewQuestionText(e.target.value)}
                                            sx={{ mt: 2 }}
                                        />
                                        <TextField
                                            select
                                            label="Question Type"
                                            variant="outlined"
                                            fullWidth
                                            value={newQuestionType}
                                            onChange={(e) => setNewQuestionType(e.target.value)}
                                            sx={{ mt: 2 }}
                                        >
                                            <MenuItem value="MCQ">MCQ</MenuItem>
                                            <MenuItem value="ShortAnswer">Short Answer</MenuItem>
                                            <MenuItem value="Paragraph">Long Answer</MenuItem>
                                        </TextField>

                                        {/* Dynamic Fields for Options */}
                                        {newQuestionType === "MCQ" && (
                                            <Box sx={{ mt: 2 }}>
                                                {newOptions.map((option, index) => (
                                                    <Box key={index} sx={{ mb: 1 }}>
                                                        <TextField
                                                            label={`Option ${index + 1}`}
                                                            variant="outlined"
                                                            fullWidth
                                                            value={option}
                                                            onChange={(e) => handleNewOptionChange(index, e)}
                                                            sx={{ mb: 1 }}
                                                        />
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => handleRemoveNewOption(index)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Remove Option
                                                        </Button>
                                                    </Box>
                                                ))}
                                                <Button variant="outlined" onClick={handleAddNewOption}>
                                                    Add Option
                                                </Button>
                                            </Box>
                                        )}
                                    </>
                                )}
                                <Button
                                    variant="outlined"
                                    onClick={() => setShowNewQuestionFields(true)}
                                    sx={{
                                        mt: 2, ml: 2,

                                        bottom: 0,
                                        backgroundColor: 'white',
                                        zIndex: 1,
                                    }}
                                >
                                    Add New Question
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleUpdateForm}
                                    sx={{ mt: 2, ml: 2 }}
                                >
                                    Update Form
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleCloseModal}
                                    sx={{ mt: 2, ml: 2 }}
                                >
                                    Close
                                </Button>
                            </>
                        ) : (
                            <Typography>No form selected</Typography>
                        )}
                    </Box>
                </Modal>

                <Modal open={openSendModal} onClose={handleCloseSendModal}>
                    <Box
                        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, maxHeight: '80vh', overflow: 'auto', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}
                    >
                        <Box display="flex" gap={2} sx={{ mb: 2 }} alignItems="center">
                            <DatePicker
                                selected={selectedDateTime}
                                onChange={(date) => setSelectedDateTime(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                customInput={<TextField fullWidth inputProps={{ readOnly: true }} />}
                                wrapperClassName="date-picker-wrapper"
                                popperPlacement="bottom-end" // Adjust this based on your needs
                            />
                            <TextField
                                label="Search Employee"
                                variant="outlined"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                sx={{ flex: 1 }} // Flex 1 makes sure it takes available space
                            />
                        </Box>

                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <TableContainer component={Paper}>
                                <Table style={{ border: '1px solid #333', borderColor: '#333' }}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#333' }}>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Name</b></TableCell>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Email</b></TableCell>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Select</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredEmployees.map((employee) => (
                                            <TableRow key={employee.emp_id}>
                                                <TableCell>{employee.name}</TableCell>
                                                <TableCell>{employee.email}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedEmployees.includes(employee.emp_id)}
                                                        onChange={(e) => handleSelectEmployee(employee.emp_id, e.target.checked)}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Button
                            variant="contained"
                            onClick={handleSend}
                            sx={{ mt: 2 }}
                        >
                            Send
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleCloseSendModal}
                            sx={{ mt: 2, ml: 2 }}
                        >
                            Close
                        </Button>
                    </Box>
                </Modal>

                <Modal open={openResponseModal} onClose={handleCloseResponseModal}>
                    <Box
                        sx={{ position: 'relative', width: '80%', maxWidth: 1000, maxHeight: '80vh', overflow: 'auto', bgcolor: 'background.paper', boxShadow: 24, p: 4, margin: 'auto', mt: 8 }}
                    >
                        <IconButton
                            onClick={handleCloseResponseModal}
                            sx={{ position: 'absolute', top: 5, right: 5, color: 'text.primary', }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Tabs value={selectedTab} onChange={handleTabChange}>
                            <Tab label="Individual Response" />
                            <Tab label="All Responses" />
                        </Tabs>

                        {selectedTab === 0 && (
                            // Individual Response Tab Content
                            <TableContainer component={Paper}>
                                <Table style={{ border: '1px solid #333', borderColor: '#333' }}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#333' }}>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Employee ID</b></TableCell>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Name</b></TableCell>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Email</b></TableCell>
                                            {/* <TableCell style={{ color: '#FFFFFF' }}><b>Timestamp</b></TableCell> */}
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Action</b></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {employeeResponses.map((response, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{response.emp_id}</TableCell>
                                                <TableCell>{response.name}</TableCell>
                                                <TableCell>{response.email}</TableCell>
                                                {/* <TableCell>{response.timestamp}</TableCell> */}
                                                <TableCell>
                                                    <Button variant="contained" color="primary" onClick={() => handleViewResponse(response)}>
                                                        View Response
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {/* {selectedTab === 1 && (
                            <TableContainer component={Paper}>
                                <Table style={{ border: '1px solid #333', borderColor: '#333' }}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#333' }}>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Name</b></TableCell>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Timestamp</b></TableCell>
                                            {selectedForm?.questions.map(question => (
                                                <TableCell key={question.question_id} style={{ color: '#FFFFFF' }}><b>{question.question}</b></TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>


                                    <TableBody>
                                        {employeeResponses.map((response, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{response.name}</TableCell>
                                                <TableCell>{response.timestamp}</TableCell>
                                                {selectedForm?.questions.map(question => (
                                                    <TableCell key={question.question_id}>
                                                        <Box display="flex" flexWrap="wrap" gap={1}>
                                                            {response.answers[question.question_id] ? (
                                                                <Chip
                                                                    label={response.answers[question.question_id] || "N/A"}
                                                                    style={{
                                                                        margin: '2px',
                                                                        backgroundColor: '#e0e0e0',
                                                                        color: '#333',
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    label="No answers"
                                                                    style={{
                                                                        margin: '2px',
                                                                        backgroundColor: '#e0e0e0',
                                                                        color: '#333',
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        )} */}


                        {selectedTab === 1 && (
                            // All Responses Tab Content
                            <TableContainer component={Paper}>
                                <Table style={{ border: '1px solid #333', borderColor: '#333' }}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#333' }}>
                                            <TableCell style={{ color: '#FFFFFF' }}><b>Name</b></TableCell>
                                            {/* <TableCell style={{ color: '#FFFFFF' }}><b>Timestamp</b></TableCell> */}
                                            {selectedForm?.questions.map(question => (
                                                <TableCell key={question.question_id} style={{ color: '#FFFFFF' }}><b>{question.question}</b></TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {employeeResponses.map((response, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{response.name}</TableCell>
                                                {/* <TableCell>{response.timestamp}</TableCell> */}
                                                {selectedForm?.questions.map(question => (
                                                    <TableCell key={question.question_id}>
                                                        <Box display="flex" flexWrap="wrap" gap={1}>
                                                            {response.answers
                                                                .filter((a) => a.question_id === question.question_id)
                                                                .map((a, idx) => (
                                                                    <Chip
                                                                        key={idx}
                                                                        label={a.answer || "N/A"}
                                                                        style={{
                                                                            margin: '2px',
                                                                            backgroundColor: '#e0e0e0',
                                                                            color: '#333',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    />
                                                                ))}
                                                        </Box>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                </Modal>

                <Modal open={openDetailModal} onClose={handleCloseDetailModal}>
                    <Box
                        sx={{
                            position: 'relative',
                            width: '60%',
                            maxWidth: 800,
                            maxHeight: '80vh',
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            margin: 'auto',
                            mt: 8
                        }}
                    >
                        <IconButton
                            onClick={handleCloseDetailModal}
                            sx={{ position: 'absolute', top: 5, right: 5, color: 'text.primary' }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Employee Responses
                        </Typography>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            // <List>
                            //     {Array.isArray(formResponses) && formResponses.map((qa, index) => (
                            //         <ListItem
                            //             key={index}
                            //             sx={{
                            //                 bgcolor: 'background.default',
                            //                 mb: 2,
                            //                 p: 2,
                            //                 borderRadius: 2,
                            //                 boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                            //             }}
                            //         >
                            //             <ListItemText
                            //                 primary={
                            //                     <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            //                         {qa.question}
                            //                     </Typography>
                            //                 }
                            //                 secondary={
                            //                     <Typography variant="body1">
                            //                         {/* Display the answer directly since it's a single value */}
                            //                         {qa.answer ? (
                            //                             <div>
                            //                                 <strong>Answer:</strong> {qa.answer}
                            //                             </div>
                            //                         ) : (
                            //                             <em>No answer available</em> // Display a message if no answer is present
                            //                         )}
                            //                     </Typography>
                            //                 }
                            //             />
                            //         </ListItem>
                            //     ))}
                            // </List>
                            <List>
                                {formResponses?.map((qa, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            bgcolor: 'background.default',
                                            mb: 2,
                                            p: 2,
                                            borderRadius: 2,
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{qa.question}</Typography>}
                                            secondary={<Typography variant="body1">
                                                {qa.answers.map((a, idx) => (
                                                    <div key={idx}>
                                                        <strong>{format(new Date(a.timestamp), 'PPpp')}:</strong> {a.answer}
                                                    </div>
                                                ))}
                                            </Typography>}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                </Modal>

            </Container>
        </div >
    );
};
const pillStyle = {
    backgroundColor: '#ffffff', // White background
    color: '#000000',           // Black text
    borderRadius: '30px',
    padding: '10px 20px',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginRight: 'auto',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    display: 'inline-flex',
    alignItems: 'center',
};

const pillCountStyle = {
    backgroundColor: '#000000', // Black background
    color: '#ffffff',           // White text
    borderRadius: '50%',
    padding: '8px 15px',
    marginLeft: '10px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
};

export default FormData;    
