// import React from 'react';

// export const ThankYou = () => {
//   return (
//     <div style={styles.container}>
//       <h1 style={styles.title}>Thank You!</h1>
//       {/* <img
//         src="httpss://media.istockphoto.com/id/1404526937/vector/thank-you-message-in-round-speech-bubble-isolated-on-yellow-background-gratitude-banner.jpg?s=612x612&w=0&k=20&c=TdlkSGqBCwDBp-0Sfc8x3IVq8Il9iwPF2lnWn_f6WkI="
//         alt="Tom and Jerry"
//         style={styles.cartoonImage}
//       /> */}
//      <h3 style={styles.message}> Your response has been recorded. We really appreciate your time and effort! </h3>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     minHeight: '100vh',
//     backgroundColor: '#f4bd69',
//     fontFamily: 'Arial, sans-serif',
//     padding: '20px',
//   },
//   title: {
//     color: '#333',
//     fontSize: '56px',
//     fontWeight: 'bold',
//     marginBottom: '20px',
//     fontFamily: "'Comic Sans MS', cursive, sans-serif",
//     textShadow: '2px 2px #f2a5a5',
//   },
//   cartoonImage: {
//     width: '300px',
//     height: 'auto',
//     marginBottom: '20px',
//   },
//   image: {
//     width: '250px',
//     height: '250px',
//     marginTop: '20px',
//     borderRadius: '20%',
//   },
//   message: {
//     color: '#555',
//     fontSize: '20px',
//     textAlign: 'center',
//   },
// };


import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ThankYou = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const formId = queryParams.get('formId');

  const handleAnotherResponse = () => {
    // Navigate back to the form with token
    navigate(`/form/${formId}?token=${token}`);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Thank You!</h1>
      <div style={styles.decorativeElement}></div>
      <h3 style={styles.message}>
        Your response has been recorded. We really appreciate your time and effort!
      </h3>
      <div style={styles.icons}>
        <span style={styles.flowerIcon}>📑</span>
        <span style={styles.starIcon}>📩</span>
        <span style={styles.heartIcon}>🖋</span>
        <span style={styles.smileIcon}>😊</span>
      </div>
      <button style={styles.submitButton} onClick={handleAnotherResponse}>
        Submit Another Response
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#ffefd5', // New, softer background color
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    position: 'relative',
    overflow: 'hidden',
  },
  title: {
    color: '#2c3e50',
    fontSize: '56px',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontFamily: "'Comic Sans MS', cursive, sans-serif",
    textShadow: '2px 2px 5px rgba(243, 156, 18, 0.6)',
  },
  decorativeElement: {
    width: '200px',
    height: '200px',
    background: 'linear-gradient(45deg, #f3ec78, #af4261)',
    borderRadius: '50%',
    position: 'absolute',
    top: '-50px',
    right: '-50px',
    opacity: '0.5',
    zIndex: '-1',
  },
  message: {
    color: '#34495e',
    fontSize: '22px',
    textAlign: 'center',
    maxWidth: '600px',
    margin: '20px 0',
    padding: '0 15px',
    lineHeight: '1.6',
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '30px',
  },
  flowerIcon: {
    fontSize: '30px',
    animation: 'bounce 2s infinite alternate',
  },
  starIcon: {
    fontSize: '30px',
    animation: 'rotate 3s infinite linear',
  },
  heartIcon: {
    fontSize: '30px',
    animation: 'pulse 1.5s infinite',
  },
  smileIcon: {
    fontSize: '30px',
    animation: 'bounce 2.5s infinite alternate',
  },
  submitButton: {
    backgroundColor: '#af4261',
    color: '#fff',
    fontSize: '18px',
    padding: '12px 24px',
    borderRadius: '25px',
    border: 'none',
    cursor: 'pointer',
    marginTop: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s',
    outline: 'none',
  },
};
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
  @keyframes bounce {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }
`, styleSheet.cssRules.length);

styleSheet.insertRule(`
  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`, styleSheet.cssRules.length);

styleSheet.insertRule(`
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
`, styleSheet.cssRules.length);

export default ThankYou;