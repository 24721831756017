import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, TextField, Box, Fade } from '@mui/material';
import axios from 'axios';
import './data.css';
import { useNavigate } from 'react-router-dom';

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]); // Ensure employees is initialized as an array
  const [openModal, setOpenModal] = useState(false);
  const [newEmployee, setNewEmployee] = useState({ name: '', email: '' });
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const validateName = (name) => {
    if (!name.trim()) {
      setNameError('Name cannot be empty.');
    } else {
      setNameError('');
    }
  };

  // Fetch all employees when the component mounts
  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('https://feedbacksvc.varnetsolutions.in/get_all_employees');
      console.log('API Response:', response.data); // Log the response to see its structure
      // Extract the employees array from the response
      if (response.data && response.data.employees) {
        setEmployees(response.data.employees);
        setTotalCount(response.data.total_count);
      } else {
        console.error('Expected an array but received:', response.data);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewEmployee({ name: '', email: '' });
    setEditingEmployee(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee({ ...newEmployee, [name]: value });

    if (name === 'email') {
      validateEmail(value);
    } else if (name === 'name') {
      validateName(value);
    }
  };

  const handleAddEmployee = async () => {
    validateName(newEmployee.name);
    validateEmail(newEmployee.email);
    if (nameError || emailError) {
      return;
    }
    try {
      if (editingEmployee) {
        // If editing, ensure at least one of the new fields is provided
        const payload = {};
        if (newEmployee.name && newEmployee.name !== editingEmployee.name) {
          payload.new_name = newEmployee.name;
        }
        if (newEmployee.email && newEmployee.email !== editingEmployee.email) {
          payload.new_email = newEmployee.email;
        }
        if (Object.keys(payload).length > 0) {
          await axios.put(`https://feedbacksvc.varnetsolutions.in/update_employee_by_name/${editingEmployee.name}`, payload);
        } else {
          console.warn('No changes detected to update.');
        }
      } else {
        // Ensure payload matches API requirements
        const payload = {
          name: newEmployee.name,
          email: newEmployee.email
        };
        // Send a POST request to add a new employee
        await axios.post('https://feedbacksvc.varnetsolutions.in/add_employee', payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      }
      fetchEmployees(); // Refresh the employee list
      handleCloseModal(); // Close the modal
    } catch (error) {
      console.error('Error adding/updating employee:', error);
    }
  };


  const handleEditClick = (employee) => {
    setEditingEmployee(employee);
    setNewEmployee({ name: employee.name, email: employee.email });
    setOpenModal(true);
  };

  const handleDeleteEmployee = async (emp_id) => {
    try {
      await axios.delete(`https://feedbacksvc.varnetsolutions.in/delete_employee_by_name/${emp_id}`);
      console.log(`Deleted employee: ${emp_id}`);
      fetchEmployees(); 
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };
  const handleViewFilledForms = (emp_id) => {
    console.log(emp_id)
    navigate(`/EmployeeForms/${emp_id}`)
  }

  return (
    <div className="specific">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px' }}>
        <div style={pillStyle}>
          Total Count: <span style={pillCountStyle}>{totalCount}</span>
        </div>
        <Fade in timeout={900}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal}
            style={{ float: 'right', margin: '10px', marginTop: '0px', transition: 'transform 0.3s' }}
            onMouseEnter={(e) => e.target.style.transform = 'scale(1.1)'}
            onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
          >
            Add Employee
          </Button>
        </Fade>
      </div>
      <TableContainer component={Paper}>
        <Fade in timeout={1000}>
          <Table style={{ border: '1px solid #333', borderColor: '#333' }}>
            <TableHead>
              <TableRow style={{ border: '1px solid #333', borderColor: '#333', backgroundColor: '#333' }}>
                <TableCell style={{ color: '#FFFFFF' }}><b>id</b></TableCell>
                <TableCell style={{ color: '#FFFFFF' }}><b>Name</b></TableCell>
                <TableCell style={{ color: '#FFFFFF' }}><b>Email</b></TableCell>
                <TableCell style={{ color: '#FFFFFF' }}><b>Actions</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee, index) => (
                <TableRow
                  key={employee.emp_id}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9', // Alternates between white and grey
                    transition: 'background-color 0.3s, transform 0.3s',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                      transform: 'scale(1.02)',
                    },
                  }}
                >
                  <TableCell>{employee.emp_id}</TableCell>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.email}</TableCell>
                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => handleViewFilledForms(employee.emp_id)}>
                      View Filled Form
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="outlined" color="primary" onClick={() => handleEditClick(employee)}>
                      Edit
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="outlined" color="secondary" onClick={() => handleDeleteEmployee(employee.emp_id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Fade>
      </TableContainer>

      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={openModal}>
          <Box sx={{ ...modalStyle }}>
            <h2>{editingEmployee ? 'Edit Employee' : 'Add New Employee'}</h2>
            <TextField
              label="Name"
              name="name"
              value={newEmployee.name}
              onChange={handleInputChange}
              error={!!nameError}
              helperText={nameError}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              value={newEmployee.email}
              onChange={handleInputChange}
              error={!!emailError}
              helperText={emailError}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEmployee}
              style={{ marginTop: '10px' }}
              disabled={!!nameError || !!emailError}
            >
              {editingEmployee ? 'Update' : 'Add'}
            </Button>

            <Button variant="outlined" onClick={handleCloseModal} style={{ marginTop: '10px', marginLeft: '10px' }}>
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>

    </div>
  );
};

const pillStyle = {
  backgroundColor: '#ffffff', // White background
  color: '#000000',           // Black text
  borderRadius: '30px',
  padding: '10px 20px',
  fontWeight: 'bold',
  marginLeft: '20px',
  marginRight: 'auto',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
};

const pillCountStyle = {
  backgroundColor: '#000000', // Black background
  color: '#ffffff',           // White text
  borderRadius: '50%',
  padding: '8px 15px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#f9f9f9',
  border: '2px solid #007bb5',
  borderRadius: '8px',
  boxShadow: '0 6px 12px rgba(0,0,0,0.3)',
  p: 4,
  transition: 'transform 0.3s ease-in-out',
};

const tableRowStyle = {
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    transform: 'scale(1.02)',
  },
};

const buttonStyle = {
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: '#0056b3',
    transform: 'scale(1.05)',
  },
};

export default EmployeeTable;

