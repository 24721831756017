import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from './varnet.png';
import { TextField, InputAdornment, IconButton, Button, Container, CircularProgress, Typography, Box } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login({ onLoginSuccess }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);

        const loginPayload = {
            username,
            password,
        };

        try {
            const response = await fetch("https://feedbacksvc.varnetsolutions.in/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginPayload),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Login successful:", data);

                // Set role_id to session storage if it exists in the response
                if (data.role_id) {
                    sessionStorage.setItem('role', data.role_id);
                    sessionStorage.setItem('token', data.token);
                    localStorage.setItem('isLoggedIn', 'true');
                }

                // Call the onLoginSuccess function if passed (optional)
                if (onLoginSuccess) {
                    onLoginSuccess();
                }

                // Redirect to the navbar route
                navigate("/form");

            } else {
                const errorData = await response.json();
                setError(errorData.message || "Login failed");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
        }
    };

    return (
        <Container
            component="main"
            maxWidth="false"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                bgcolor: "#87CEEB", // Fallback color in case image doesn't load
                width: "100%",
                padding: 0, // Remove padding if you want image to cover the whole page
                backgroundImage: `url('https://png.pngtree.com/thumb_back/fh260/background/20231027/pngtree-hexagonal-abstract-background-with-a-black-textured-surface-image_13704307.png')`, // Replace with your background image path
                backgroundSize: "cover", // Cover the entire container
                backgroundPosition: "center", // Center the background image
                backgroundRepeat: "no-repeat", // Avoid repeating the image
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    bgcolor: "background.paper",
                    width: 500,
                    height: 400
                }}
            >
                <img src={logo} alt="Logo" style={{ width: 300, marginBottom: 20, height: 70 }} />
                <Typography component="h1" variant="h5" gutterBottom>
                    Login
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {error && (
                    <Typography color="error" variant="body2">
                        {error}
                    </Typography>
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                >
                    {loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        "Login"
                    )}
                </Button>
            </Box>
        </Container>
    );
}

export default Login;

