import React, { useState } from "react";
import { Container, TextField, Typography, Button, Switch, Box, Rating, Select, MenuItem, IconButton, InputLabel, FormControl, Checkbox, FormControlLabel, Radio, Grid, RadioGroup } from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { motion } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import './Formpg.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Form() {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [questions, setQuestions] = useState([]);
    const [error, setError] = useState("");
    const [multipleTime, setMultipleTime] = useState(false); // New state for the switch

    const handleAddQuestion = () => {
        setQuestions([...questions, { type: "", question: "", options: [], selectedOptions: [], rating: 0 }]);
    };

    const handleQuestionChange = (index, field, value) => {
        const updatedQuestions = [...questions];

        if (field === "options") {
            const options = (value || "").toString().split(",").map((opt) => opt.trim());
            updatedQuestions[index][field] = options;
        } else if (field === "selectedOptions") {
            updatedQuestions[index][field] = value;
        } else {
            updatedQuestions[index][field] = value;
        }

        setQuestions(updatedQuestions);
    };
    const handleRemoveQuestion = (index) => {
        const updatedQuestions = questions.filter((_, i) => i !== index);
        setQuestions(updatedQuestions);
    };

    const handleSubmit = async () => {
        if (!title || !description || questions.length === 0) {
            setError("Please fill out the title, description, and add at least one question.");
            return;
        }
        setError("");

        const formData = {
            title,
            description,
            questions,
            multiple_time: multipleTime
        };

        try {
            const response = await fetch('https://feedbacksvc.varnetsolutions.in/create_form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                const data = await response.json();
                console.log('Form saved successfully:', data);
                toast.success('Form created successfully!');
                setTitle("");
                setDescription("");
                setQuestions([]);
                setMultipleTime(false);
            } else {
                console.error('Failed to save form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const renderQuestionInput = (question, index) => {
        if (question.type === "MCQ") {
            return (
                <Box>
                    {Array.from({ length: 4 }).map((_, i) => (
                        <Box key={i} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <TextField
                                variant="outlined"
                                placeholder={`Option ${String.fromCharCode(65 + i)}`}
                                value={question.options[i] || ""}
                                onChange={(e) => handleQuestionChange(index, "options", [...question.options.slice(0, i), e.target.value, ...question.options.slice(i + 1)])}
                                sx={{ marginRight: 1 }}
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label={`Option ${String.fromCharCode(65 + i)}`}
                            />
                        </Box>
                    ))}
                </Box>
            );
        }

        if (question.type === "Boolean") {
            return (
                <RadioGroup row>
                    {["Yes", "No"].map((opt, i) => (
                        <FormControlLabel
                            key={i}
                            control={<Radio />}
                            label={opt}
                        />
                    ))}
                </RadioGroup>
            );
        }

        if (question.type === "ShortAnswer") {
            return <TextField fullWidth variant="outlined" multiline rows={2} placeholder="Your answer here" />;
        }

        if (question.type === "Paragraph") {
            return <TextField fullWidth variant="outlined" multiline rows={4} placeholder="Your paragraph here" />;
        }

        if (question.type === "Rating") {
            return (
                <Box>
                    <Typography variant="body2">Select Rating:</Typography>
                    <Rating
                        name={`rating-${index}`}
                        value={question.rating || 0}
                        onChange={(e, newValue) => handleQuestionChange(index, "rating", newValue)}
                        precision={1}
                        max={5}
                    />
                </Box>
            );
        }
        if (question.type === "DateOfBirth") {
            return (
                <DatePicker
                    selected={question.dateOfBirth}
                    onChange={(date) => handleQuestionChange(index, "dateOfBirth", date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Date"
                    showYearDropdown
                    scrollableYearDropdown
                    minDate={new Date(1900, 0, 1)} // Start date range
                    maxDate={new Date()} // End date range as the current date
                    yearDropdownItemNumber={100} // Number of years to show in the dropdown at once
                />
            );
        }
        if (question.type === "MSQ") {
            return (
                <Box>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Options (comma separated)"
                        value={question.options.join(", ")}
                        onChange={(e) => handleQuestionChange(index, "options", e.target.value)}
                        sx={{ marginBottom: 1 }}
                    />
                    {question.options.map((opt, i) => (
                        <FormControlLabel
                            key={i}
                            control={
                                <Checkbox
                                    checked={question.selectedOptions.includes(opt)}
                                    onChange={(e) => {
                                        const updatedOptions = e.target.checked
                                            ? [...question.selectedOptions, opt]
                                            : question.selectedOptions.filter((o) => o !== opt);
                                        handleQuestionChange(index, "selectedOptions", updatedOptions);
                                    }}
                                />
                            }
                            label={opt}
                        />
                    ))}
                </Box>
            );
        }
        
        
        
        // if (question.type === "DateOfBirth") {
        //     return (
        //         <DatePicker
        //             selected={question.dateOfBirth}
        //             onChange={(date) => handleQuestionChange(index, "dateOfBirth", date)}
        //             dateFormat="dd/MM/yyyy"
        //             placeholderText="Select Date"
        //             showYearDropdown
        //             scrollableYearDropdown
        //         />
        //     );
        // }

        return null;
    };

    const renderQuestionPreview = (question, index) => {
        return (
            <Box key={index} sx={{ marginBottom: 2 }}>
                <Typography variant="body1" gutterBottom>
                    {index + 1}. {question.question}
                </Typography>
                {question.type === "MCQ" && (
                    <Box>
                        {question.options.map((opt, i) => (
                            <Typography key={i} variant="body2">
                                - {opt}
                            </Typography>
                        ))}
                    </Box>
                )}
                {question.type === "Boolean" && (
                    <Box>
                        {question.options.map((opt, i) => (
                            <Typography key={i} variant="body2">
                                - {opt}
                            </Typography>
                        ))}
                    </Box>
                )}
                {question.type === "ShortAnswer" && (
                    <Typography variant="body2">Short Answer</Typography>
                )}

                {question.type === "Paragraph" && (
                    <Typography variant="body2">Paragraph</Typography>
                )}

                {question.type === "Rating" && (
                    <Typography variant="body2">Rating: {question.rating} stars</Typography>
                )}

            </Box>
        );
    };

    return (
        <div >
            <Container maxWidth="lg" sx={{ marginTop: 4, display: 'flex', gap: 4 }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Create a New Form
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Form Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{ marginBottom: 2 }}

                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Form Description"
                        multiline
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        sx={{ marginBottom: 2 }}

                    />
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Questions
                    </Typography>
                    {questions.map((q, index) => (
                        <Box key={index} sx={{ marginBottom: 3 }} >
                            <TextField
                                fullWidth
                                variant="outlined"
                                label={`Question ${index + 1}`}
                                value={q.question}
                                onChange={(e) => handleQuestionChange(index, "question", e.target.value)}
                                sx={{ marginBottom: 1 }}

                            />
                            <FormControl fullWidth variant="outlined" sx={{ marginBottom: 1 }}>
                                <InputLabel style={{ textAlign: 'center' }}>Question Type</InputLabel>
                                <Select
                                    value={q.type}
                                    onChange={(e) => handleQuestionChange(index, "type", e.target.value)}
                                    label="Question Type"
                                >
                                    <MenuItem value="MCQ">Multiple Choice (Checkbox)</MenuItem>
                                    <MenuItem value="Boolean">Boolean (Yes/No)</MenuItem>
                                    <MenuItem value="ShortAnswer">Short Answer</MenuItem>
                                    <MenuItem value="Paragraph">Paragraph</MenuItem>
                                    <MenuItem value="Rating">Rating (Stars)</MenuItem>
                                    <MenuItem value="DateOfBirth">Date of Birth</MenuItem>
                                    <MenuItem value="MSQ">Multiple Select (Checkboxes)</MenuItem>
                                </Select>
                            </FormControl>
                            {q.type === "MCQ" && (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Options (comma separated)"
                                    value={q.options.join(", ")}
                                    onChange={(e) => handleQuestionChange(index, "options", e.target.value)}
                                    sx={{ marginBottom: 1 }}
                                />
                            )}
                            {q.type === "Boolean" && (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Options (comma separated)"
                                    value={q.options.join(", ")}
                                    onChange={(e) => handleQuestionChange(index, "options", e.target.value)}
                                    sx={{ marginBottom: 1 }}
                                />
                            )}
                            {renderQuestionInput(q, index)}
                            <IconButton
                                color="secondary"
                                onClick={() => handleRemoveQuestion(index)}
                                aria-label="Remove Question"
                            >
                                <RemoveCircleOutline />
                            </IconButton>
                        </Box>
                    ))}
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddCircleOutline />}
                        onClick={handleAddQuestion}
                        sx={{ marginBottom: 2, fontWeight: 'bold' }}
                    >
                        Add Question
                    </Button>
                    <br />
                    <FormControlLabel
                        control={<Switch checked={multipleTime} onChange={(e) => setMultipleTime(e.target.checked)} />}
                        label="Allow multiple submissions"
                        sx={{ marginBottom: 2 }}
                    />
                    {error && (
                        <Typography sx={{ marginBottom: 2 }}>
                            {error}
                        </Typography>
                    )}
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{ marginTop: 2, marginBottom: 2 }}
                    >
                        Save Form
                    </Button>
                </Box>

                <Box
                    sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <motion.img
                        src="https://www.mentoringcomplete.com/wp-content/uploads/2023/04/Your-paragraph-text-22-1.png" // Replace with the path to your GIF
                        alt="Animated Cartoon"
                        style={{ maxWidth: '100%', height: 'auto' }}
                        animate={{ y: [0, -10, 0] }}
                        transition={{ duration: 1, repeat: Infinity, repeatType: "loop" }}
                    />
                </Box>
            </Container>
            <ToastContainer />
        </div>
    );
}

export default Form;

