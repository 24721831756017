import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    Typography, 
    CircularProgress, 
    Alert 
} from '@mui/material';

const EmployeeForms = () => {
    const { empId } = useParams();
    const [formsData, setFormsData] = useState([]);
    const [totalFilledForms, setTotalFilledForms] = useState(0);
    const [totalForms, setTotalForms] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFormsData = async () => {
            try {
                const response = await axios.get(`https://feedbacksvc.varnetsolutions.in/api/forms_filled_by_employee/${empId}`);
                setFormsData(response.data.filled_forms);
                setTotalFilledForms(response.data.total_filled_forms);
                setTotalForms(response.data.total_forms);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch data');
                setLoading(false);
            }
        };

        fetchFormsData();
    }, [empId]);

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Paper elevation={3} sx={{ padding: 3, backgroundColor: '#f5f5f5' }}>
            <Typography variant="h4" gutterBottom>
                Employee ID: {empId}
            </Typography>
            <Typography variant="subtitle1">
                Total Filled Forms: <strong>{totalFilledForms}</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Total Forms: <strong>{totalForms}</strong>
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Form ID</strong></TableCell>
                            <TableCell><strong>Title</strong></TableCell>
                            <TableCell><strong>Description</strong></TableCell>
                            <TableCell><strong>Timestamp</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formsData.map((form) => (
                            <TableRow key={form.form_id}>
                                <TableCell>{form.form_id}</TableCell>
                                <TableCell>{form.title}</TableCell>
                                <TableCell>{form.description}</TableCell>
                                <TableCell>{new Date(form.timestamp).toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default EmployeeForms;
