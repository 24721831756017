import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Box, Typography, Rating, TextField, Button, RadioGroup, Radio, FormControlLabel, Checkbox } from "@mui/material";
import './SpecificPage.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'; // Import format function from date-fns

function FormView() {
    const { formId } = useParams(); // Get formId from URL
    const [form, setForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [responses, setResponses] = useState({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchForm = async () => {
            setLoading(true);
            try {
                let query = new URLSearchParams(window.location.search);
                let token = query.get('token');
                if (!token) {
                    navigate('/');
                }
                const response = await fetch(`https://feedbacksvc.varnetsolutions.in/get_form/${formId}`);
                const data = await response.json();
                setForm(data);
            } catch (error) {
                console.error("Error fetching form:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchForm();
    }, [formId, navigate]);

    const handleResponseChange = (questionId, value) => {
        setResponses((prevResponses) => {
            const existingResponses = prevResponses[questionId] || [];
            if (Array.isArray(value)) {
                // For MSQ type, update the array of selected options
                return {
                    ...prevResponses,
                    [questionId]: value,
                };
            } else {
                return {
                    ...prevResponses,
                    [questionId]: value,
                };
            }
        });
    };

    const handleSubmit = async () => {
        const allAnswered = form.questions.every((question) => {
            const response = responses[question.question_id];
            if (question.type === 'Boolean' || question.type === 'MCQ') {
                return response !== undefined;
            } else if (question.type === 'MSQ') {
                return Array.isArray(response) && response.length > 0;
            } else if (question.type === 'Rating') {
                return response !== undefined && response > 0;
            } else if (question.type === 'ShortAnswer' || question.type === 'Paragraph') {
                return response && typeof response === 'string' && response.trim() !== '';
            } else if (question.type === 'DateOfBirth') {
                return response !== null && response !== undefined;
            }
            return false;
        });

        if (!allAnswered) {
            toast.error('Please answer all questions before submitting.');
            return;
        }

        const formattedResponses = { ...responses };

        Object.keys(formattedResponses).forEach((questionId) => {
            const question = form.questions.find(q => q.question_id.toString() === questionId);
            if (question && question.type === 'DateOfBirth' && formattedResponses[questionId]) {
                // Format the date to 'dd/MM/yyyy'
                formattedResponses[questionId] = format(new Date(formattedResponses[questionId]), 'dd/MM/yyyy');
            }
        });

        setIsLoading(true);
        try {
            let query = new URLSearchParams(window.location.search);
            let token = query.get('token');
            const payload = {
                form_id: parseInt(formId, 10),
                answers: formattedResponses,
            };

            const response = await fetch('https://feedbacksvc.varnetsolutions.in/submit_answers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
            const result = await response.json();

            if (response.ok) {
                toast.success(result.message || 'Form submitted successfully!');
                setTimeout(() => {
                    navigate(`/thank-you?formId=${formId}&token=${token}`);
                }, 3000);
            } else {
                toast.error(result.message || 'Error submitting form');
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error(`Error: ${error.message}`);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 3000); // Show loader for 2 seconds
        }
    };


    if (loading) {
        return <CircularProgress />;
    }

    if (!form) {
        return <Typography variant="h6">Form not found or error loading form.</Typography>;
    }

    return (
        <div className="specific-page">
            <ToastContainer />
            <Container >
                <Box sx={{ mt: 4 }}>
                    <Box sx={{ mt: 4, mb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{
                            backgroundColor: '#ffffff',
                            padding: '20px',
                            borderRadius: '15px',
                            boxShadow: '0px 0px 15px rgba(0,0,0,0.6)',
                            textAlign: 'center',
                            maxWidth: '1100px'
                        }}>
                            <Typography variant="h4" gutterBottom style={{
                                background: 'linear-gradient(to right, #000, #000)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                fontWeight: 'bold',
                                fontFamily: 'Arial, sans-serif'
                            }}>
                                {form.title}
                            </Typography>
                            <Typography variant="body1" gutterBottom style={{ marginTop: '15px', color: '#333' }}>
                                {form.description}
                            </Typography>
                        </div>
                    </Box>

                    {form.questions.map((question) => (
                        <Box key={question.question_id} sx={{ marginBottom: 3, padding: 2, borderRadius: 2, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 10px rgba(0,0,0,0.8)' }}>
                            <Typography variant="h6">{question.question} <span style={{ color: 'red', fontSize: '1.2em' }}>*</span></Typography>
                            {question.type === 'MCQ' && (
                                <RadioGroup
                                    value={responses[question.question_id] || ''}
                                    onChange={(e) => handleResponseChange(question.question_id, e.target.value)}
                                >
                                    {question.options.map((option, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={option}
                                            control={<Radio />}
                                            label={option}
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                            {question.type === 'Boolean' && (
                                <RadioGroup
                                    value={responses[question.question_id] || ''}
                                    onChange={(e) => handleResponseChange(question.question_id, e.target.value)}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                    />
                                </RadioGroup>
                            )}
                            {question.type === 'ShortAnswer' && (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={responses[question.question_id] || ''}
                                    onChange={(e) => handleResponseChange(question.question_id, e.target.value)}
                                    placeholder="Type your answer here"
                                />
                            )}
                            {question.type === 'Paragraph' && (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={responses[question.question_id] || ''}
                                    onChange={(e) => handleResponseChange(question.question_id, e.target.value)}
                                    placeholder="Type your answer here"
                                />
                            )}

                            {question.type === 'Rating' && (
                                <Box>
                                    <Typography variant="body2">Select Rating:</Typography>
                                    <Rating
                                        name={`rating-${question.question_id}`}
                                        value={responses[question.question_id] || 0}
                                        onChange={(e, newValue) => handleResponseChange(question.question_id, newValue)}
                                        precision={1}
                                        max={5}
                                    />
                                </Box>
                            )}

                            {question.type === 'DateOfBirth' && (
                                <Box>
                                    <DatePicker
                                        selected={responses[question.question_id] || null}
                                        onChange={(date) => handleResponseChange(question.question_id, date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Select Date of Birth"
                                        popperPlacement="bottom-start"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100} // Show 100 years at a time
                                    />
                                    {responses[question.question_id] && (
                                        <Typography variant="body2">
                                            Selected Date: {format(new Date(responses[question.question_id]), 'dd/MM/yyyy')}
                                        </Typography>
                                    )}
                                </Box>
                            )}

                            {question.type === 'MSQ' && (
                                <Box>
                                    {question.options.map((option, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={(responses[question.question_id] || []).includes(option)}
                                                    onChange={(e) => {
                                                        const updatedOptions = e.target.checked
                                                            ? [...(responses[question.question_id] || []), option]
                                                            : (responses[question.question_id] || []).filter((opt) => opt !== option);
                                                        handleResponseChange(question.question_id, updatedOptions);
                                                    }}
                                                />
                                            }
                                            label={option}
                                        />
                                    ))}
                                </Box>
                            )}

                        </Box>
                    ))}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{ mt: 3, marginLeft: "92%", position: 'sticky' }}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>

                </Box>
            </Container>
        </div>
    );

}

export default FormView;
