import React from 'react';
import Navbar from './navbar'; // Adjust import path as necessary
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div>
      <Navbar />
      <main style={{ padding: '20px' }}>
        <Outlet /> {/* Renders the nested route components */}
      </main>
    </div>
  );
};

export default Layout;
