import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './component/login';
import Form from './component/form';
import FormData from './component/FormData';
import Layout from './component/Layout ';
import FormView from './component/FormView';
import Employee from './component/Employee';
import { ThankYou } from './component/ThankYou';
import EmployeeForms from './component/EmployeeForms ';
import ProtectedRoute from './component/ProtectedRoute';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path="form" element={<ProtectedRoute><Form /></ProtectedRoute>} />
          <Route path="FormData" element={<ProtectedRoute><FormData /></ProtectedRoute>} />
          <Route path="form/:formId" element={<FormView />} />
          <Route path="Employee" element={<ProtectedRoute><Employee /></ProtectedRoute>} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path="EmployeeForms/:empId" element={<ProtectedRoute><EmployeeForms /></ProtectedRoute>} />
        </Route>



      </Routes>
    </Router>
  );
}

export default App;
