import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, useScrollTrigger } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

function Navbar() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const role = sessionStorage.getItem('role');
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn') // Clear session storage
    sessionStorage.clear(); // Clear session storage
    navigate('/'); // Redirect to login page
  };

  // Only render the Navbar if role is 1
  if (role !== '1') {
    return null;
  }

  return (
    <AppBar
      position={trigger ? 'fixed' : 'static'}
      sx={{
        bgcolor: '#333',
        boxShadow: trigger ? '0px 4px 20px rgba(0, 0, 0, 0.3)' : 'none',
        transition: 'background-color 0.3s ease',
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            fontFamily: 'Arial, sans-serif',
            fontWeight: 'bold',
            letterSpacing: '1px',
            color: '#ffffff',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
          }}
        >
          Varnet Solutions Private Limited
        </Typography>
        <Box>
          <Button component={Link} to="/form" color="inherit" sx={{ color: '#ffffff', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' , fontWeight: 'bold'} }}>Form</Button>
          <Button component={Link} to="/FormData" color="inherit" sx={{ color: '#ffffff', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' , fontWeight: 'bold'} }}>Survey</Button>
          <Button component={Link} to="/Employee" color="inherit" sx={{ color: '#ffffff', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)', fontWeight: 'bold' } }}>Employee</Button>
          <Button  onClick={handleLogout} color="inherit" sx={{ color: '#ffffff',  '&:hover': {  backgroundColor: 'rgba(255, 255, 255, 0.2)', fontWeight: 'bold'},}}>LogOut</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
